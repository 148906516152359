.services {
    background-color: #2c3e50;
    color: white;
    padding: 50px 0;
    text-align: center;
  }
  
  .header-section {
    margin-bottom: 30px;
  }
  
  .header-section h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .header-section p {
    font-size: 18px;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .cards {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .card {
    background-color: white;
    color: #2c3e50;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .card h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 16px;
  }

  /* Responsive Design */
@media (max-width: 767px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    margin: 5px 0; /* Adjust margin for vertical spacing */
  }
}