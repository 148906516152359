html, body {
  scroll-behavior: smooth;
}

.header {
  /*
  position: sticky;
  top: 0;
  */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo img {
  height: 50px;
}

.title {
  flex-grow: 1;
  text-align: left;
}

.title h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu ul li {
  margin-left: 20px;
}

.nav-menu ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.nav-menu ul li a:hover {
  color: #007bff;
}

/* Responsive Design */
@media (max-width: 767px) {
  
  .nav-menu {
   visibility: hidden;
    
  }

}

