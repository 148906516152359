.home {
    width: 100%;
    height: 100vh;
  }
  
  .hero-section {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .hero-image {
    width: 100%;
    height: auto;
  }
  
  .hero-text {
    position:absolute;
    bottom: 5%;
    right: 5%;
    background-color: white;
    width: 75vh;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .hero-text h1 {
    margin: 0;
    font-size: 24px;
    color: #2c3e50;
  }
  
  .hero-text p {
    font-size: 16px;
    color: #2c3e50;
  }
  
  /* Responsive Design */
  @media (max-width: 767px) {
    .hero-text {
      position: relative;
      bottom: auto;
      right: auto;
      margin: 20px auto;
      text-align: center;
      width: 90%;
    }
  }
  
  @media (min-width: 768px) {
    .hero-text {
      left: auto;
      transform: none;
    }
  
   
  }
  
  @media (min-width: 1024px) {
    .hero-text {
      left: auto;
      transform: none;
    }
  
    
    
  }
  
  